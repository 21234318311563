<div class="d-flex flex-column p-5">

	<div class="d-flex mb-3">
		<h1>{{'radical-identifier.overview.title' | translate}}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.content.length > 0; else emptyState">
			<div class="d-inline-flex flex-row justify-content-end w-100 ">
				<button id="open-create-radical-identifier-modal" type="button" *permissionsAllowed="['CREATE_RADICAL_IDENTIFIERS']"
						class="btn btn-primary" (click)="openCreateModal()"><i
					class="ti ti-plus me-2 fs-4"></i>{{'radical-identifier.create-button' | translate}}</button>
			</div>
			<app-table [addButtonColumn]="true" [headers]="headers"
					   [headerNamePrefix]="'radical-identifier.overview.headers.'"
					   [id]="'radical-identifier-table'">
				<ng-container *ngIf="page.content.length > 0 else noResults">
					<tr *ngFor="let radicalIdentifier of page.content; let i = index">
						<td class="w-25">{{ radicalIdentifier.name }}</td>
						<td>{{ radicalIdentifier.usualDesignation }}</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<i id="edit-radical-identifier-{{i}}" *permissionsAllowed="['UPDATE_RADICAL_IDENTIFIERS']" class="ti ti-pencil float-end me-2 fs-4 pointer" (click)="openUpdateModal(radicalIdentifier)"></i>
								<i id="delete-radical-identifier-{{i}}" *permissionsAllowed="['DELETE_RADICAL_IDENTIFIERS']" class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteRadicalIdentifier(radicalIdentifier)"
								   confirmationMessage="{{getConfirmationMessage()}}">
								</i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</div>
	</div>

</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'radical-identifier.overview.search.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>

<ng-template #emptyState>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{ 'radical-identifier.overview.no-content' | translate }}</p>
		<button class="btn btn-primary" (click)="openCreateModal()"><i
			class="ti ti-plus me-2 fs-4"></i>{{'radical-identifier.create-button' | translate}}</button>
	</div>
</ng-template>
