import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {RadicalIdentifier} from '../_model/radical-identifier';
import {CreateOrUpdateRadicalIdentifierRequest} from '../_model/create.or.update.radical.identifier.request';


@Injectable()
export class RadicalIdentifierService {

	readonly baseUrl = environment.backend_url + '/radicalidentifier';

	constructor(private http: HttpClient) {
	}

	search(pageNumber: number): Observable<Page<RadicalIdentifier>> {
		return this.http.get<Page<RadicalIdentifier>>(this.baseUrl + '/search', {params: {'page': pageNumber}});
	}

	create(request: CreateOrUpdateRadicalIdentifierRequest): Observable<RadicalIdentifier> {
		return this.http.post<RadicalIdentifier>(this.baseUrl, request);
	}

	update(uuid: string, request: CreateOrUpdateRadicalIdentifierRequest): Observable<RadicalIdentifier> {
		return this.http.put<RadicalIdentifier>(`${this.baseUrl}/${uuid}`, request);
	}

	delete(radicalIdentifierUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${radicalIdentifierUuid}`);
	}

	findAll(): Observable<Array<RadicalIdentifier>> {
		return this.http.get<Array<RadicalIdentifier>>(this.baseUrl);
	}

}
