<div class="vh-100 min-vh-100 position-sticky top-0 text-darkblue-800">
	<div class="d-flex flex-column justify-content-start align-items-center h-100">
		<div class="d-flex flex-row justify-content-center align-items-center mt-5 mb-5 flex-shrink-0">
			<div class="d-flex flex-column w-100 justify-content-center align-items-center">
				<img src="assets/img/logo.png" class="w-75 mb-3" alt=""/>
				<img src="assets/img/logo-name.png" class="w-75" alt=""/>
			</div>
		</div>

		<div class="sidebar-nav px-2">
			<div class="flex-grow-1">
				<ul class="nav flex-column w-100 py-4 user-select-none" id="menu">
					<li class="nav-item" *permissionsAllowed="['VIEW_CUSTOMERS', 'VIEW_USERS', 'VIEW_PROJECTS']">
						<a class="nav-link-item collapsed pointer"
						   (click)="toggleCollapse('identityAndAccessManagement')">
							<i class="ti ti-adjustments fs-4 me-3"></i>
							<span
								class="align-self-center">{{'navigation.identity-and-access-management' | translate}}</span>
							<div class="ms-auto">
								<i class="ti ti-caret-up fs-5" *ngIf="visible['identityAndAccessManagement']"></i>
								<i class="ti ti-caret-down fs-5" *ngIf="!visible['identityAndAccessManagement']"></i>
							</div>
						</a>

						<ul class="collapse" #identityAndAccessManagement
							[style.display]="visible['identityAndAccessManagement'] ? 'inline' : 'none'">
							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_CUSTOMERS']">
								<a class="nav-link-item collapsed" [routerLink]="'customers'">
									<i class="ti ti-plane-tilt fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.customer' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'">
								<a class="nav-link-item collapsed" [routerLink]="'users'"
								   *permissionsAllowed="['VIEW_USERS']">
									<i class="ti ti-users fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.user' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'">
								<a class="nav-link-item collapsed" [routerLink]="'projects'"
								   *permissionsAllowed="['VIEW_PROJECTS']">
									<i class="ti ti-book fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.project' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_PROGRAMS']">
								<a class="nav-link-item collapsed" [routerLink]="'program'">
									<i class="ti ti-code fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.program' | translate}}</span>
								</a>
							</li>
						</ul>
					</li>

					<li class="nav-item" *permissionsAllowed="['UPLOAD_DBS', 'VIEW_DBS', 'VIEW_ARTICLES', 'VIEW_PRODUCTION_CATALOGUES', 'VIEW_DATASETS']">
						<a class="nav-link-item collapsed pointer" (click)="toggleCollapse('getMyDB' )">
							<i class="ti ti-coins fs-4 me-3"></i>
							<span class="align-self-center">{{'navigation.my-db' | translate}}</span>
							<div class="ms-auto">
								<i class="ti ti-caret-up fs-5" *ngIf="visible['getMyDB']"></i>
								<i class="ti ti-caret-down fs-5" *ngIf="!visible['getMyDB']"></i>
							</div>
						</a>

						<ul class="collapse" [style.display]="visible['getMyDB'] ? 'inline' : 'none'">
							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_ARTICLES']">
								<a class="nav-link-item collapsed" [routerLink]="'articles'">
									<i class="ti ti-server-cog fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.articles' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_PRODUCTION_CATALOGUES']">
								<a class="nav-link-item collapsed" [routerLink]="'production-catalogue'">
									<i class="ti ti-book-2 fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.production-catalogue' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_DATASETS']">
								<a class="nav-link-item collapsed" [routerLink]="'datasets'">
									<i class="ti ti-layers-intersect fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.datasets' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_SOURCE_RADICAL_IDENTIFIERS']">
								<a class="nav-link-item collapsed" [routerLink]="'source-radical-identifier'">
									<i class="ti ti-book-2 fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.source-radical-identifier' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_DBS']">
								<a class="nav-link-item collapsed" [routerLink]="'dbs'">
									<i class="ti ti-download fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.db-download' | translate}}</span>
								</a>
							</li>
						</ul>
					</li>

					<li class="nav-item" *permissionsAllowed="['VIEW_VIEWER_DBS', 'UPLOAD_VIEWER_DBS']">
						<a class="nav-link-item collapsed pointer" (click)="toggleCollapse('viewer')">
							<i class="ti ti-eye fs-4 me-3"></i>
							<span class="align-self-center">{{'navigation.viewer' | translate}}</span>
							<div class="ms-auto">
								<i class="ti ti-caret-up fs-5" *ngIf="visible['viewer']"></i>
								<i class="ti ti-caret-down fs-5" *ngIf="!visible['viewer']"></i>
							</div>
						</a>

						<ul #viewer class="collapse" [style.display]="visible['viewer'] ? 'inline' : 'none'">

							<li class="nav-item" [routerLinkActive]="'active'"
								*permissionsAllowed="['VIEW_VIEWER_DBS']">
								<a class="nav-link-item collapsed" [routerLink]="'viewer'">
									<i class="ti ti-file-database fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.navdb-viewer' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'"
								*permissionsAllowed="['VIEW_UPDATES_VIEWER_DBS']">
								<a class="nav-link-item collapsed" [routerLink]="'updates-viewer'">
									<i class="ti ti-file-upload fs-4 me-3"></i>
									<span
										class="align-self-center">{{'navigation.navdb-updates-viewer' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'"
								*permissionsAllowed="['VIEW_JOBS']">
								<a class="nav-link-item collapsed" [routerLink]="'jobs'">
									<i class="ti ti-file-upload fs-4 me-3"></i>
									<span
										class="align-self-center">{{'navigation.jobs' | translate}}</span>
								</a>
							</li>
						</ul>
					</li>

					<li class="nav-item" *permissionsAllowed="['VIEW_PROGRAMS', 'VIEW_FOLDERS', 'UPLOAD_NOTICES', 'VIEW_NOTICES']">
						<a class="nav-link-item collapsed pointer" (click)="toggleCollapse('noticesAndAlerts')">
							<i class="ti ti-file-alert fs-4 me-3"></i>
							<span class="align-self-center">{{'navigation.notices-and-alerts.general' | translate}}</span>
							<div class="ms-auto">
								<i class="ti ti-caret-up fs-5" *ngIf="visible['noticesAndAlerts']"></i>
								<i class="ti ti-caret-down fs-5" *ngIf="!visible['noticesAndAlerts']"></i>
							</div>
						</a>

						<ul class="collapse"
							[style.display]="visible['noticesAndAlerts'] ? 'inline' : 'none'">

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_FOLDERS']">
								<a class="nav-link-item collapsed" [routerLink]="'folder'">
									<i class="ti ti-folder fs-4 me-3"></i>
									<span class="align-self-center">{{'navigation.folders' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_NOTICES']">
								<a class="nav-link-item collapsed" [routerLink]="'notice-overview'">
									<i class="ti ti-file-alert fs-4 me-3"></i>
									<span
										class="align-self-center">{{'navigation.notices-and-alerts.overview' | translate}}</span>
								</a>
							</li>

							<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['VIEW_NOTICES']">
								<a class="nav-link-item collapsed" [routerLink]="'doc-overview'">
									<i class="ti ti-file-description fs-4 me-3"></i>
									<span
										class="align-self-center">{{'navigation.notices-and-alerts.documents' | translate}}</span>
								</a>
							</li>
						</ul>
					</li>
					<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['DIGIS_INTEGRATION']">
						<a class="nav-link-item collapsed" [routerLink]="'digis'">
							<i class="ti ti-cloud-up fs-4 me-3"></i>
							<span class="align-self-center">{{'navigation.digis' | translate}}</span>
						</a>
					</li>

					<li class="nav-item">
						<hr>
					</li>

					<li class="nav-item" [routerLinkActive]="'active'" *permissionsAllowed="['ANY']">
						<a class="nav-link-item collapsed" [routerLink]="'contact'">
							<i class="ti ti-info-square-rounded fs-4 me-3"></i>
							<span class="align-self-center">{{'navigation.contact' | translate}}</span>
						</a>
					</li>
				</ul>
			</div>

			<div class="flex-shrink-0 mt-3 px-3">
				<div class="mb-3 text-break" *ngIf="user$ | async as user">
					<div><span id="profile-name"><b>{{user.firstName}} {{user.lastName}}</b></span></div>
					<div><span id="profile-email">{{user.email}}</span></div>
				</div>

				<app-logout-component></app-logout-component>

				<div class="version" *permissionsAllowed="['VIEW_BATCH_VERSION']">
					{{version$ | async }}
				</div>
			</div>
		</div>
	</div>
</div>
