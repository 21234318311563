import {Component, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {finalize, Observable, Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RadicalIdentifier} from '../_model/radical-identifier';
import {RadicalIdentifierService} from '../_service/radical-identifier.service';
import {UpdateForm} from '../../shared/modal/update.form';
import {CreateOrUpdateRadicalIdentifierRequest} from '../_model/create.or.update.radical.identifier.request';
import {ModularFormComponent} from '../../shared/modular-forms/modular-form/modular-form.component';

@Component({
	selector: 'app-radical-identifier-modal',
	templateUrl: './radical-identifier-form-modal.component.html'
})
export class RadicalIdentifierFormModalComponent extends ModularFormComponent implements OnDestroy, UpdateForm<RadicalIdentifier> {

	submitting = false;
	radicalIdentifier: RadicalIdentifier;

	private subscription = new Subscription();

	constructor(public activeModal: NgbActiveModal,
				private formBuilder: FormBuilder,
				private radicalIdentifierService: RadicalIdentifierService) {
		super('radical-identifier');

		this.form.addControl('name', new FormControl('', [Validators.required, Validators.maxLength(255)]));
		this.form.addControl('usualDesignation', new FormControl('', [Validators.maxLength(255)]));
	}

	setContent(content: RadicalIdentifier): void {
		this.radicalIdentifier = content;
		this.form.patchValue(this.radicalIdentifier);
	}

	close(): void {
		if (!this.submitting) {
			this.activeModal.dismiss();
		}
	}

	save(): void {
		if (this.submitting) {
			return;
		}
		this.submitting = true;
		this.subscription.add(this.doSave()
			.pipe(
				finalize(() => {
					this.submitting = false;
				})
			).subscribe((radicalIdentifier) => {
				this.activeModal.close(radicalIdentifier);
			})
		);
	}

	doSave(): Observable<RadicalIdentifier> {
		if (!this.radicalIdentifier) {
			return this.radicalIdentifierService.create(this.createRequest());
		} else {
			return this.radicalIdentifierService.update(this.radicalIdentifier.uuid, this.createRequest());
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private createRequest(): CreateOrUpdateRadicalIdentifierRequest {
		return {
			name: this.form.get('name').value,
			usualDesignation: this.form.get('usualDesignation').value
		};
	}
}
