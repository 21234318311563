<div class="d-flex flex-column p-5">
	<div class="d-flex mb-3">
		<h1>{{ 'dataset.overview.title' | translate }}</h1>
	</div>

	<div *ngIf="page$ | async as page">
		<div *ngIf="page.content.length > 0; else emptyState">

			<div class="d-inline-flex flex-row justify-content-between w-100 mb-3">
				<div><!-- placeholder for searching--></div>

				<a [routerLink]="'/dataset-upload'" *permissionsAllowed="['UPLOAD_DATASETS']" class="text-decoration-none">
					<button type="button" class="btn btn-primary">
						<i class="ti ti-upload me-2 fs-4"></i>{{ 'general.upload' | translate }}
					</button>
				</a>
			</div>

			<app-table [addButtonColumn]="true"
					   [headers]="headers"
					   [headerNamePrefix]="'dataset.overview.headers.'"
					   [id]="'dataset-table'">
				<ng-container *ngIf="page.content.length > 0 else noResults">
					<tr *ngFor="let dataset of page.content; let i = index">
						<td>{{ dataset.radicalIdentifier.name }}</td>
						<td>{{ dataset.identifier }}</td>
						<td>{{ dataset.usualDesignation }}</td>
						<td>{{ dataset.airacCycle ? dataset.airacCycle : 'NA' }}</td>
						<td>{{ dataset.releaseNumber }}</td>
						<td>{{ dataset.uploadDate }}</td>
						<td>
							<div class="d-flex justify-content-end align-items-center">
								<button class="float-start btn btn-table w-lg-td btn-download"
										id="download-dataset-files-{{i}}"
										(click)="openDownloadModal(dataset.uuid)">
									<i class="ti ti-file-download fs-4 me-2"></i>
									{{ dataset.nrOfFiles }} {{ 'general.files' | translate }}
								</button>
								<i id="delete-dataset-{{i}}" *permissionsAllowed="['DELETE_DATASETS']"
								   class="ti ti-trash float-end text-danger me-2 fs-4 pointer"
								   appConfirmationModal
								   (confirmAction)="deleteDataset(dataset.uuid)"
								   [confirmationMessage]="'dataset.overview.delete.confirmation-message.are-you-sure' | translate">
								</i>
							</div>
						</td>
					</tr>
				</ng-container>
			</app-table>
			<app-pagination [page]="page" (pageEvent)="loadPage($event)"></app-pagination>
		</div>
	</div>

</div>

<ng-template #noResults>
	<tr>
		<td colspan="100%">
			<p class="empty-state-text my-4 justify-content-center">{{ 'dataset.overview.search.no-results' | translate }}</p>
		</td>
	</tr>
</ng-template>

<ng-template #emptyState>
	<div class="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
		<p class="empty-state-text mb-4">{{ 'dataset.overview.no-content' | translate }}</p>
		<a [routerLink]="'/dataset-upload'" *permissionsAllowed="['UPLOAD_DATASETS']" class="text-decoration-none">
			<button type="button" class="btn btn-primary">
				<i class="ti ti-upload me-2 fs-4"></i>{{ 'general.upload' | translate }}
			</button>
		</a>
	</div>
</ng-template>
