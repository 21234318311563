<form [formGroup]="form" class="mt-4">
	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-input name="reference"></app-modular-form-control-input>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input name="usualDesignation"></app-modular-form-control-input>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-input name="type"></app-modular-form-control-input>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-multiselect name="programs" [options]="programs$ | async" [showTooltip]="true"></app-modular-form-control-multiselect>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input name="offset" type="number" [showTooltip]="true" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-input>
		</div>
	</div>

	<hr>
	<br>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-checkbox name="tailored"></app-modular-form-control-checkbox>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-input *ngIf="getControlValue('tailored')" name="tailoredCode"></app-modular-form-control-input>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-multiselect name="projects" [options]="projects$ | async"></app-modular-form-control-multiselect>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-select name="sourceRadicalIdentifierUuid" [options]="sourceRadicalIdentifiers$ | async"></app-modular-form-control-select>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-select name="flightManagementSystem" [options]="flightManagementSystems$ | async"></app-modular-form-control-select>
		</div>

		<div class="col-md-4">
			<app-modular-form-control-input name="databaseRadicalIdentifier" type="text"></app-modular-form-control-input>
		</div>
	</div>

	<ng-container *ngIf="getControlValue('flightManagementSystem')">
		<div class="row mb-3">
			<div class="col-md-4">
				<app-modular-form-control-input name="dqrReference"></app-modular-form-control-input>
			</div>
			<div class="col-md-4">
				<app-modular-form-control-input name="compatibleApp"></app-modular-form-control-input>
			</div>
		</div>
	</ng-container>

	<div class="row mb-3">
		<div class="col-md-4">
			<app-modular-form-control-select name="rnpAr" [options]="rnpArs"></app-modular-form-control-select>
		</div>
		<div class="col-md-4">
			<app-modular-form-control-select name="geoAreaType" [options]="geoAreasTypes$ | async"></app-modular-form-control-select>
		</div>
		<div class="col-md-4">
			<ng-container *ngIf="getControlValue('geoAreaType') === 'REGIONAL'">
				<app-modular-form-control-multiselect name="geoAreas" [options]="(geoAreas$ | async)?.['REGIONAL']" [hideLabel]="true"></app-modular-form-control-multiselect>
			</ng-container>

			<ng-container *ngIf="getControlValue('geoAreaType') === 'GEOGRAPHIC'">
				<app-modular-form-control-select name="geoAreas" [options]="(geoAreas$ | async)?.['GEOGRAPHIC']" [hideLabel]="true"></app-modular-form-control-select>
			</ng-container>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-6">
			<app-modular-form-control-checkbox name="lpv"></app-modular-form-control-checkbox>
		</div>
	</div>

	<div class="row mb-3">
		<div class="col-md-6">
			<app-modular-form-control-select name="exportControl" [options]="exportControls"></app-modular-form-control-select>
		</div>
	</div>

	<ng-container *ngIf="article">
		<app-modular-form-control-static-list name="flightDatabases" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>
		<app-modular-form-control-static-list name="customers" *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>
	</ng-container>

	<app-invalid-feedback></app-invalid-feedback>
</form>
