import {Component, OnDestroy, OnInit} from '@angular/core';
import {
	BehaviorSubject,
	combineLatest, filter,
	Observable,
	ReplaySubject,
	startWith,
	Subject,
	Subscription,
	switchMap, tap
} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {FlightDatabaseService} from '../_service/flight-database.service';
import {TsvDiffLine} from '../_model/tsv-diff-line';
import {FlightDatabaseTsvFileData} from '../_model/flight-database-tsv-file-data';
import {FlightDatabase} from '../_model/flight-database';
import {Content} from '../../shared/model/content';
import {DropdownData} from '../../shared/dropdown-multiselect/dropdown-data';
import {HttpResponse} from '@angular/common/http';
import {mapContentToContentArray} from '../../shared/model/content-array';

@Component({
	selector: 'app-db-updates-viewer',
	templateUrl: './db-updates-viewer.component.html',
	styleUrls: ['./db-updates-viewer.component.css']
})
export class DbUpdatesViewerComponent implements OnInit, OnDestroy {

	public readonly DIFF_COLUMN = 'db.updates-viewer.headers.diff';
	loading= false;
	tsvDiffLinesPage$: Observable<Page<TsvDiffLine>>;
	subscription = new Subscription();
	private currentPage$ = new Subject<number>();
	selectedFlightDatabase$ = new ReplaySubject<FlightDatabase>(1);
	selectedFlightDatabaseTsvFile$ = new ReplaySubject<FlightDatabaseTsvFileData>(1);
	updatesOnly$ = new BehaviorSubject(false);
	selectedSearchCriteria$ = new BehaviorSubject<Content>({});
	resetSearch$ = new Subject<void>();
	headers: Header[];
	viewSelectionDropdownData: DropdownData[];

	constructor(private dbService: FlightDatabaseService) {
	}

	ngOnInit(): void {
		this.tsvDiffLinesPage$ = combineLatest([
			this.selectedFlightDatabase$,
			this.selectedFlightDatabaseTsvFile$,
			this.updatesOnly$,
			this.selectedSearchCriteria$,
			this.currentPage$.pipe(startWith(1))
		])
			.pipe(
				tap(() => this.loading = true),
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				filter(([flightDatabase, file, updatesOnly, selectedSearchCriteria, currentPage]) => {
					const canContinue = file !== null;
					if (!canContinue) {
						this.loading = false;
					}
					return canContinue;
				}),
				switchMap(([flightDatabase, file, updatesOnly, selectedSearchCriteria, currentPage]) =>
					this.dbService.searchTsvDiffLines(currentPage - 1, flightDatabase.uuid, file.uuid, updatesOnly, selectedSearchCriteria)
				),
				tap(() => this.loading = false)
			);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	flightDatabaseSelected(flightDatabase: any): void {
		this.selectedFlightDatabase$.next(flightDatabase);
		this.selectedFlightDatabaseTsvFile$.next(null);
		this.resetSearch$.next();
	}

	tsvFileSelected(file: any): void {
		this.subscription.add(this.selectedFlightDatabase$.subscribe(db => this.dbService.logViewerUpdatesConsults(db.uuid, file.uuid)));
		this.loadPage(1);

		this.extractHeaders(file);
		this.updateVisibleColumns(this.headers.map(header => header.name));

		this.viewSelectionDropdownData = this.headers.map(header => {
			return {
				label: header.name,
				value: header.name,
				isSelected: true
			};
		});

		this.selectedFlightDatabaseTsvFile$.next(file);
	}

	updateUpdatesOnly(event: any): void {
		this.updatesOnly$.next(event);
	}

	updateVisibleColumns(columns: string[]): void {
		this.resetSearch();
		this.headers.forEach(header => {
			header.visible = columns.indexOf(header.name) >= 0;
		});
	}

	search(selectedCriteria: Content): void {
		this.selectedSearchCriteria$.next(selectedCriteria);
		this.loadPage(1);
	}

	resetSearch(): void {
		this.resetSearch$.next();
		this.loadPage(1);
	}

	getExportFunction(): Observable<HttpResponse<Blob>> {
		return combineLatest([
			this.selectedFlightDatabase$,
			this.selectedFlightDatabaseTsvFile$,
			this.selectedSearchCriteria$
		])
			.pipe(
				switchMap(([flightDatabase, file, data]) => {
					const content = mapContentToContentArray(data);
					return this.dbService.exportTsvDiffLines(flightDatabase.uuid, file.uuid, content);
				})
			);
	}

	private extractHeaders(file: any): void {
		this.headers = file.columnHeaders.map((headerValue: string) => {
			return {name: headerValue, searchable: true};
		}) as Header[];
		this.headers.splice(0, 0, {name: this.DIFF_COLUMN, searchable: true});
	}
}
