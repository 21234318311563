<app-base-modal [title]="getTitleTranslationKey() | translate" [disabled]="submitting">
	<app-article-form #articleForm="articleForm" [article]="article" [readonly]="submitting"/>
	<ng-container actions>
		<button id="submit-article" type="button" class="btn btn-primary"
				(click)="submitForm(articleForm.getArticle())"
				[disabled]="articleForm.isInvalid() || submitting">
			{{ getSubmitTranslationKey() | translate }}
		</button>
	</ng-container>
</app-base-modal>
