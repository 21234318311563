import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {DatasetService} from '../_service/dataset.service';
import {DatasetOverview} from '../_model/dataset-overview';
import {DatasetDownloadFilesModalComponent} from '../download-files-modal/dataset-download-files-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {TranslateService} from '@ngx-translate/core';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';

@Component({
	selector: 'app-dataset-overview',
	templateUrl: './dataset-overview.component.html'
})
export class DatasetOverviewComponent implements OnInit, OnDestroy {

	page$: Observable<Page<DatasetOverview>>;
	headers: Header[] = [
		{name: 'radical-identifier'},
		{name: 'identifier'},
		{name: 'usual-designation'},
		{name: 'airac-cycle'},
		{name: 'release-number'},
		{name: 'upload-date'},
		{name: 'nr-of-files'}

	];
	subscription = new Subscription();
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private datasetService: DatasetService,
				private modalService: NgbModal,
				private translateService: TranslateService,
				private toastrMessageService: ToastrMessageService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.datasetService.search(currentPage - 1, null))
			);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openDownloadModal(datasetUuid: string): void {
		this.subscription.add(this.datasetService.getFiles(datasetUuid).subscribe(files => {
			const modalRef = this.modalService.open(DatasetDownloadFilesModalComponent, {windowClass: 'small-footer'});
			modalRef.componentInstance.setContext(datasetUuid, files);
		}));
	}

	deleteDataset(uuid: string): void {
		this.subscription.add(this.datasetService.delete(uuid)
			.subscribe(() => {
				this.toastrMessageService.show({
					type: ToastrMessageType.SUCCESS,
					messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('dataset.overview.delete.success-message')}`
				});
				this.refresh$.next();
			}));
	}
}
