<label class="form-label big">{{ 'production-catalogue.form.articles.label' | translate }}</label><br>
<span class="empty-state-text subtitle mb-3" *ngIf="!getArticleCount()">
	{{ 'production-catalogue.form.articles.placeholder' | translate }}
</span>
<app-modular-form-control-multiselect name="selectedArticles"
									  [itemsShowLimit]="0"
									  [options]="articles"></app-modular-form-control-multiselect>

<ng-container *ngFor="let x of getSelectedArticles(); let i = index">
	<div class="d-flex align-items-center mb-3 flex-nowrap gap-big px-3">
		<app-modular-form-control-input [name]="'articles.' + i + '.article'"></app-modular-form-control-input>

		<app-modular-form-control-date [name]="'articles.' + i + '.productionDate'"/>

		<app-modular-form-control-input [name]="'articles.' + i + '.radicalIdentifier'"></app-modular-form-control-input>

		<app-modular-form-control-input [name]="'articles.' + i + '.tailoredReference'"></app-modular-form-control-input>
		<i class="ti ti-trash float-end text-danger me-2 fs-4 pointer" *ngIf="!readonly" (click)="removeArticle(x)">
		</i>
	</div>
</ng-container>
