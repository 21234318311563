import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AuthConfigModule} from './auth/auth-config.module';
import {PermissionsModule} from './shared/permission/permissions.module';
import {ToastrMessageModule} from './shared/toastr/toastr.message.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from './shared/table/table.module';
import {CustomerModule} from './customer/customer.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SidebarModule} from './sidebar/sidebar.module';
import {RouterModule} from '@angular/router';
import {UserModule} from './user/user.module';
import {AuthModule} from './auth/auth.module';
import {ErrorHandlingModule} from './shared/error-handling/error-handling.module';
import {DbModule} from './db/db.module';
import {CustomDateParserFormatter} from './shared/datepicker/custom-date-parser-formatter';
import {CustomNgbDateAdapter} from './shared/datepicker/custom-ngb-date-adapter';
import {NoticeModule} from './notices/notice.module';
import {ArticleModule} from './article/article.module';
import {ContactModule} from './contact/contact.module';
import {ModularFormsModule} from './shared/modular-forms/modular-forms.module';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {FlightManagementSystemModule} from './flight-management-system/flight-management-system.module';
import {ProjectModule} from './project/project.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';
import {ProductionCatalogueModule} from './productioncatalogue/production-catalogue.module';
import locale from '@angular/common/locales/nl-BE';
import {JobModule} from './job/job.module';
import {DigisModule} from './digis/digis.module';
import {DatasetModule} from './dataset/dataset.module';
import {RadicalIdentifierModule} from './radicalidentifier/radical-identifier.module';

registerLocaleData(locale);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		CommonModule,
		AuthConfigModule,
		PermissionsModule,
		TableModule,
		CustomerModule,
		UserModule,
		JobModule,
		NoticeModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		SidebarModule,
		RouterModule,
		AuthModule,
		BrowserAnimationsModule,
		NgbModule,
		ToastrMessageModule,
		ErrorHandlingModule,
		DbModule,
		ArticleModule,
		ContactModule,
		ProductionCatalogueModule,
		DigisModule,
		ModularFormsModule,
		NgMultiSelectDropDownModule.forRoot(),
		FlightManagementSystemModule,
		ProjectModule,
		RadicalIdentifierModule,
		DatasetModule,
		NgxGoogleAnalyticsModule.forRoot(environment.analytics.measurement_id),
		NgxGoogleAnalyticsRouterModule
	],
	providers: [
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
		{provide: NgbDateAdapter, useClass: CustomNgbDateAdapter},
		{provide: LOCALE_ID, useValue: 'nl-BE'}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}
