import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {RadicalIdentifier} from '../_model/radical-identifier';
import {RadicalIdentifierService} from '../_service/radical-identifier.service';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ModalService} from '../../shared/modal/modal.service';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {RadicalIdentifierFormModalComponent} from '../radical-identifier-form-modal/radical-identifier-form-modal.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
	selector: 'app-radical-identifier-overview',
	templateUrl: './radical-identifier-overview.component.html'
})
export class RadicalIdentifierOverviewComponent implements OnInit, OnDestroy {
	page$: Observable<Page<RadicalIdentifier>>;
	headers: Header[] = [
		{name: 'radicalIdentifier'},
		{name: 'usualDesignation'}
	];
	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	private subscription = new Subscription();

	constructor(
		private readonly radicalIdentifierService: RadicalIdentifierService,
		private readonly translateService: TranslateService,
		private modalService: ModalService,
		private toastrMessageService: ToastrMessageService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.radicalIdentifierService.search(currentPage - 1))
			);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	loadPage(page: number): void {
		this.currentPage$.next(page);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(RadicalIdentifierFormModalComponent, 'radical-identifier').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(radicalIdentifier: RadicalIdentifier): void {
		this.modalService.openUpdateModal(RadicalIdentifierFormModalComponent, radicalIdentifier, 'radical-identifier').subscribe(() => this.refresh$.next());
	}

	deleteRadicalIdentifier(radicalIdentifier: RadicalIdentifier): void {
		this.subscription.add(
			this.radicalIdentifierService.delete(radicalIdentifier.uuid)
				.subscribe({
					next: () => {
						this.showToastr(ToastrMessageType.SUCCESS, 'radical-identifier.delete.success-message', false);
						this.refresh$.next();
					},
					error: (errorResponse) => {
						this.showToastr(ToastrMessageType.ERROR, errorResponse.error.errorCode, true);
					}
				}));
	}

	showToastr(type: ToastrMessageType, translationKey: string, sticky: boolean): void {
		this.toastrMessageService.show({
			type: type,
			messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant(translationKey)}`,
			sticky: sticky
		});
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('radical-identifier.delete.confirmation-message.are-you-sure')}</p>`;
	}
}
