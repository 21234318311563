import { Component } from '@angular/core';
import {ModularFormControlInputWithLayout} from '../modular-form-control-layout/modular-form-control-input-with-layout';

@Component({
	selector: 'app-modular-form-control-textarea',
	templateUrl: './modular-form-control-textarea.component.html'
})
export class ModularFormControlTextareaComponent extends ModularFormControlInputWithLayout{

}
