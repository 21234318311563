<form [formGroup]="form">

	<app-modular-form-control-input name="reference"
									*ngIf="noProductionParametersGiven() || (canRequestImmediately$ | async)"></app-modular-form-control-input>

	<app-modular-form-control-input name="usualDesignation"></app-modular-form-control-input>

	<app-modular-form-control-input name="type"></app-modular-form-control-input>

	<app-modular-form-control-multiselect name="programs"
										  [options]="programs$ | async"
										  [showTooltip]="true"></app-modular-form-control-multiselect>

	<app-modular-form-control-input name="offset"
									type="number"
									[showTooltip]="true"
									*permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-input>

	<ng-container
		*ngIf="!article || article.canEditProductionParameters || (hasViewProductionParametersPermission()|async)">
		<hr>
		<div class="nav-item list-unstyled">
			<a class="nav-link-item collapsed pointer"
			   (click)="toggleCollapse('productionParameters')">
			<span
				class="align-self-center">{{ 'article.form.production-parameters' | translate }}</span>
				<div class="ms-auto">
					<i class="ti ti-caret-up fs-5" *ngIf="visible['productionParameters']"></i>
					<i class="ti ti-caret-down fs-5" *ngIf="!visible['productionParameters']"></i>
				</div>
			</a>

			<div class="collapse" [style.display]="visible['productionParameters'] ? 'inline' : 'none'">
				<app-modular-form-control-checkbox name="tailored"/>
				<app-modular-form-control-input *ngIf="getControlValue('tailored')" class="w-75"
												name="tailoredCode" layout="stack-inline"/>

				<app-modular-form-control-multiselect name="projects"
													  [options]="projects$ | async"></app-modular-form-control-multiselect>

				<app-modular-form-control-select name="flightManagementSystem"
												 [options]="flightManagementSystems$ | async"/>

				<ng-container *ngIf="getControlValue('flightManagementSystem')">
					<app-modular-form-control-input name="dqrReference"/>
					<app-modular-form-control-input name="compatibleApp"/>
				</ng-container>

				<app-modular-form-control-select name="geoAreaType"
												 [options]="geoAreasTypes$ | async"/>

				<ng-container *ngIf="getControlValue('flightManagementSystem')">
					<div class="alert alert-warning" role="alert" *ngIf="!getControlValue('dqrReference')">
						<span [innerHTML]="'article.form.alert.dqr-reference-not-found' | translate"></span>
					</div>
				</ng-container>
				<app-modular-form-control-multiselect *ngIf="getControlValue('geoAreaType') === 'REGIONAL'"
													  name="geoAreas"
													  [options]="(geoAreas$ | async)?.['REGIONAL']"
													  [hideLabel]="true"/>

				<app-modular-form-control-select *ngIf="getControlValue('geoAreaType') === 'GEOGRAPHIC'"
												 name="geoAreas"
												 [options]="(geoAreas$ | async)?.['GEOGRAPHIC']"
												 [hideLabel]="true"/>
				<app-modular-form-control-select name="radicalIdentifierUuid" [options]="radicalIdentifiers$ | async"/>
				<app-modular-form-control-select name="rnpAr" [options]="rnpArs"/>
				<app-modular-form-control-checkbox name="lpv"/>
				<app-modular-form-control-select name="exportControl" [options]="exportControls"/>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="article">
		<app-modular-form-control-static-list name="flightDatabases"
											  *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>

		<app-modular-form-control-static-list name="customers"
											  *permissionsAllowed="['VIEW_ARTICLE_PARAMETERS']"></app-modular-form-control-static-list>
	</ng-container>

	<app-invalid-feedback></app-invalid-feedback>
</form>
