import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {CommonModule} from '@angular/common';
import {SearchModule} from '../shared/search/search.module';
import {RadicalIdentifierOverviewComponent} from './radical-identifier-overview/radical-identifier-overview.component';
import {RadicalIdentifierService} from './_service/radical-identifier.service';
import {RadicalIdentifierFormModalComponent} from './radical-identifier-form-modal/radical-identifier-form-modal.component';
import {ModalModule} from '../shared/modal/modal.module';
import {ToastrMessageModule} from '../shared/toastr/toastr.message.module';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {ValidatedInputModule} from '../shared/validated-input/validated-input.module';

@NgModule({
	declarations: [
		RadicalIdentifierOverviewComponent,
		RadicalIdentifierFormModalComponent
	],
	providers: [
		RadicalIdentifierService
	],
	imports: [
		CommonModule,
		TranslateModule,
		TableModule,
		ModalModule,
		ToastrMessageModule,
		PaginationModule,
		PermissionsModule,
		ModularFormsModule,
		FormsModule,
		ReactiveFormsModule,
		ErrorHandlingModule,
		SearchModule,
		ValidatedInputModule
	]
})
export class RadicalIdentifierModule {

}
