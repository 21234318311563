import {Component, Input, OnInit} from '@angular/core';
import {SelectOption} from '../../../shared/modular-forms/_model/select-option';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductionCatalogue} from '../../_model/production-catalogue';

@Component({
	selector: 'app-production-catalogue-article-form',
	templateUrl: './article-form.component.html',
	styleUrls: ['./article-form.component.css']
})
export class ArticleFormComponent implements OnInit {

	@Input() articles: SelectOption[];

	@Input() prodCat: ProductionCatalogue;

	@Input() readonly: boolean;

	@Input() form: FormGroup;

	formArray: FormArray;

	formControl: FormControl;

	ngOnInit(): void {
		this.formArray = this.form.get('articles') as FormArray;
		this.formControl = this.form.get('selectedArticles') as FormControl;

		this.formControl.valueChanges.subscribe((selectedOptions) => {
			this.updateArticleList(selectedOptions);
		});

		if (this.prodCat) {
			this.prodCat.articles.forEach(articleData => {
				const selectOption = this.articles.filter(value => value.id === articleData.articleUuid)[0];
				if (selectOption) {
					this.addArticle(selectOption, articleData.productionDate, articleData.tailoredReference);
				}
			});
		}
	}

	private updateArticleList(selectedOptions: SelectOption[]): void {
		this.removeDeselectedValues(selectedOptions);
		this.addSelectedValues(selectedOptions);
	}

	private removeDeselectedValues(selectedOptions: SelectOption[]): void {
		this.getArticleUuids().forEach((uuid: string) => {
			if (!selectedOptions.map((el: SelectOption) => {
				return el.id;
			}).includes(uuid)) {
				const indexToRemove = this.formArray.controls.findIndex((x: AbstractControl) => (x.value.articleUuid === uuid));
				this.formArray.removeAt(indexToRemove);
			}
		});
	}

	private addSelectedValues(selectedOptions: SelectOption[]): void {
		selectedOptions.forEach((el: SelectOption) => {
			if (!this.getArticleUuids().includes(el.id)) {
				const selectOption = this.articles.filter(value => value.id === el.id)[0];
				this.addArticle(selectOption, null, null);
			}
		});
	}

	getSelectedArticles(): SelectOption[] {
		return this.formControl.value;
	}

	private getArticleUuids(): string[] {
		return this.formArray.controls.map((control: AbstractControl) => {
			return control.get('articleUuid').value;
		});
	}

	private articlesLocked() : boolean {
		return (this.prodCat && this.prodCat.status.status !== 'REDACTION_IN_PROGRESS') || this.readonly;
	}

	getArticleCount(): number {
		return this.getSelectedArticles()?.length;
	}

	addArticle(option: SelectOption, productionDate: string | null, tailoredReference: string | null): void {
		const controls: { [key: string]: FormControl } = {};
		controls['articleUuid'] = new FormControl(option.id);
		controls['article'] = new FormControl(option.label, [Validators.required]);
		controls['article'].disable();
		controls['radicalIdentifier'] = new FormControl(option.value?.radicalIdentifier,[]);
		controls['radicalIdentifier'].disable();
		controls['tailoredReference'] = new FormControl(option.value?.tailored ? tailoredReference : 'N/A', option.value?.tailored ? [Validators.required] : []);
		controls['productionDate'] = new FormControl(productionDate, [Validators.required]);

		if (this.articlesLocked()) {
			controls['articleUuid'].disable();
			controls['productionDate'].disable();
			controls['tailoredReference'].disable();
		}

		const group = new FormGroup(controls);
		this.formArray.push(group);
	}

	removeArticle(opt: SelectOption): void {
		if (!this.articlesLocked()) {
			const newVal = this.formControl.value as SelectOption[];
			const index = newVal.indexOf(opt);
			newVal.splice(index, 1);

			this.formControl.patchValue(newVal);
			this.formArray.removeAt(index);
		}
	}

}
