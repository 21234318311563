import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, startWith, Subject, Subscription, switchMap} from 'rxjs';
import {Page} from '../../shared/pagination/page';
import {Header} from '../../shared/table/header';
import {ProjectService} from '../_service/project.service';
import {ModalService} from '../../shared/modal/modal.service';
import {ToastrMessageService} from '../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {Project} from '../_model/project';
import {ToastrMessageType} from '../../shared/toastr/toastr.message.type';
import {ProjectFormModalComponent} from '../project-form-modal/project-form-modal.component';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html'
})
export class ProjectOverviewComponent implements OnInit {

	protected page$: Observable<Page<Project>>;
	protected readonly headers: Header[] = [
		{name: 'name'}
	];

	private subscription = new Subscription();

	private refresh$ = new Subject<void>();
	private currentPage$ = new Subject<number>();

	constructor(private projectService: ProjectService,
				private modalService: ModalService,
				private toastrMessageService: ToastrMessageService,
				private translateService: TranslateService) {
	}

	ngOnInit(): void {
		this.page$ = combineLatest([this.currentPage$.pipe(startWith(1)), this.refresh$.pipe(startWith(''))])
			.pipe(
				switchMap(([currentPage]) => this.projectService.search(currentPage - 1))
			);
	}

	loadPage(page: number): void {
		this.page$ = this.projectService.search(page - 1);
	}

	openCreateModal(): void {
		this.modalService.openCreateModal(ProjectFormModalComponent, 'project').subscribe(() => this.refresh$.next());
	}

	openUpdateModal(project: Project): void {
		this.modalService.openUpdateModal(ProjectFormModalComponent, project, 'project').subscribe(() => this.refresh$.next());
	}

	deleteProject(project: Project): void {
		this.subscription.add(
			this.projectService.delete(project.uuid)
				.subscribe({
					next: () => {
						this.showToastr(ToastrMessageType.SUCCESS, 'project.delete.success-message', false);
						this.refresh$.next();
					},
					error: (errorResponse) => {
						this.showToastr(ToastrMessageType.ERROR, errorResponse.error.errorCode, true);
					}
				}));
	}

	showToastr(type: ToastrMessageType, translationKey: string, sticky: boolean): void {
		this.toastrMessageService.show({
			type: type,
			messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant(translationKey)}`,
			sticky: sticky
		});
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('project.delete.confirmation-message.are-you-sure')}</p>`;
	}

}

